<template>
    <div>
        <recess-divider variant="large" show-line />

        <div class="row">
            <div class="col-12">
                <h2
                    :class="
                        isAdministrator
                            ? 'qa-admin-student-details-table-title'
                            : 'qa-provider-invoice-table-title'
                    "
                >
                    {{ PROVIDER.Order.OrderDetails.UserInfoDetails.Title }}
                </h2>
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <div class="qa-user-first-name">
                    <h5 class="qa-user-first-name-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.FirstName.Label }}</h5>
                    <p class="qa-user-first-name-value">{{ user.firstName || '-' }}</p>
                </div>

                <div class="qa-user-middle-name">
                    <h5 class="qa-user-middle-name-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.MiddleName.Label }}</h5>
                    <p class="qa-user-middle-name-value">{{ user.middleName || '-' }}</p>
                </div>

                <div class="qa-user-surname">
                    <h5 class="qa-user-surname-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.Surname.Label }}</h5>
                    <p class="qa-user-surname-value">{{ user.surname || '-' }}</p>
                </div>

                <div class="qa-user-email">
                    <h5 class="qa-user-email-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.Email.Label }}</h5>
                    <p class="u-text-wrap qa-user-email-value">{{ user.email || '-' }}</p>
                </div>

                <div class="qa-user-phone-number">
                    <h5 class="qa-user-phone-number-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.PhoneNumber.Label }}</h5>
                    <p class="qa-user-phone-number-value">{{ user.phoneNumber || '-' }}</p>
                </div>
            </div>

            <div class="col-4">
                <div class="qa-user-address-street">
                    <h5 class="qa-user-address-street-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.Street.Label }}</h5>
                    <p class="qa-user-address-street-value">{{ user.address.street || '-' }}</p>
                </div>

                <div class="qa-user-address-house-number">
                    <h5 class="qa-user-address-house-number-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.HouseNumber.Label }}</h5>
                    <p class="qa-user-address-house-number-value">
                        {{ user.address.houseNumber || '-' }}
                    </p>
                </div>

                <div v-if="user.address.houseNumberAdditive && showProperties.houseNumberAdditive" class="qa-user-address-house-number-additive">
                    <h5 class="qa-user-address-house-number-additive-label">
                        {{ PROVIDER.Order.OrderDetails.UserInfoDetails.HouseNumberAdditive.Label }}
                    </h5>
                    <p class="qa-user-address-house-number-additive-value">
                        {{ user.address.houseNumberAdditive || '-' }}
                    </p>
                </div>

                <div class="qa-user-address-postal-code">
                    <h5 class="qa-user-address-postal-code-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.PostalCode.Label }}</h5>
                    <p class="qa-user-address-postal-code-value">{{ user.address.postalCode || '-' }}</p>
                </div>

                <div class="qa-user-address-city">
                    <h5 class="qa-user-address-city-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.City.Label }}</h5>
                    <p class="qa-user-address-city-value">{{ user.address.city || '-' }}</p>
                </div>

                <div class="qa-user-address-country-code">
                    <h5 class="qa-user-address-country-code-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.CountryCode.Label }}</h5>
                    <p class="qa-user-address-country-code-value">
                        {{ user.address.countryCode || '-' }}
                    </p>
                </div>
            </div>

            <div class="col-4 qa-user-birth-city">
                <div v-if="hasBirthCity">
                    <h5 class="qa-user-birth-city-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.BirthCity.Label }}</h5>
                    <p class="qa-user-birth-city-value">{{ user.birthCity || '-' }}</p>
                </div>

                <div v-if="hasBirthCountryCode" class="qa-user-birth-country-code">
                    <h5 class="qa-user-birth-country-code-label">
                        {{ PROVIDER.Order.OrderDetails.UserInfoDetails.BirthCountryCode.Label }}
                    </h5>
                    <p class="qa-user-birth-country-code-value">
                        {{ user.birthCountryCode || '-' }}
                    </p>
                </div>

                <div v-if="showProperties.birthDate" class="qa-user-birth-date">
                    <h5 class="qa-user-birth-date-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.BirthDate.Label }}</h5>

                    <p v-if="hasBirthDate" 
                        class="qa-user-birth-date-value"
                    >
                        {{ user.birthDate | formatDate }}
                    </p>
                    <p v-else 
                        class="qa-user-birth-date-value"
                    >-</p>
                </div>

                <div class="qa-user-company">
                    <h5 class="qa-user-company-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.Company.Label }}</h5>
                    <p class="qa-user-company-value">{{ user.company || '-' }}</p>
                </div>

                <div v-if="hasDepartment && showProperties.department" class="qa-user-department">
                    <h5 class="qa-user-department-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.Department.Label }}</h5>
                    <p class="qa-user-department-value">
                        {{ user.department || '-' }}
                    </p>
                </div>

                <div v-if="externalEmployeeCode" class="qa-user-external-employee-code">
                    <h5 class="qa-user-external-employee-code-label">
                        {{ PROVIDER.Order.OrderDetails.UserInfoDetails.ExternalEmployeeCode.Label }}
                    </h5>
                    <p class="qa-user-external-employee-code-value">
                        {{ externalEmployeeCode || '-' }}
                    </p>
                </div>

                <div v-if="invoicedBy" class="qa-user-invoiced-by">
                    <h5 class="qa-user-invoiced-by-label">{{ PROVIDER.Order.OrderDetails.UserInfoDetails.InvoicedBy.Label }}</h5>
                    <p class="qa-user-invoiced-by-value">{{ invoicedBy || '-' }}</p>
                </div>

                <slot name="edit-invoice-on-behalf-of"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import UserService from '../../../../../shared/services/UserService'

export default {
    props: {
        showProperties: {
            type: Object,
            default: () => {
                return {
                    houseNumberAdditive: true,
                    birthDate: true,
                    department: true
                }
            }
        },
        user: {
            type: Object,
            default: null
        },
        invoicedBy: {
            type: String,
            default: null
        },
        externalEmployeeCode: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            PROVIDER,
            userService: new UserService(),
            isAdministrator: false
        }
    },
    async created() {
        this.isAdministrator = await this.userService.isAdministrator()
    },
    computed: {
        hasBirthCity() {
            return this.user.birthCity
        },
        hasBirthCountryCode() {
            return this.user.birthCountryCode
        },
        hasBirthDate() {
            return this.user.birthDate
        },
        hasDepartment() {
            return this.user.department
        }
    }
}
</script>
